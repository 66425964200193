import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const rahulExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Rahul Goti",
    designation: "Head of Sales & Marketing",
    image: '../assets/img/ronak-pipaliya/rahul-goti.png',
    tag: `rahul-goti`, 
    phones: ['(+91) 8511887775'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['rahul@vasundhara.io'],
    linkedin: 'https://www.linkedin.com/in/rj-rahull-entrepreneur',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+918511887775`,
    description: [
        `He is a dynamic and results-driven Head of Sales & Marketing with a track record of success at Vasundhara Infotech, contributing significantly to the company's growth.`,
        `Demonstrates exceptional understanding of business dynamics, positively impacting company projects.`,
        `Successfully led multiple projects, showcasing remarkable leadership skills that contributed significantly to Vasundhara Infotech's success.`,
        `He employs strong leadership skills to build and lead multifunctional teams, consistently achieving customer objectives.`,
        `He proactively communicates project progress to team members, fostering a culture of continuous learning and development.`,
        `He ensures seamless communication with clients and team members at all project stages, consistently delivering projects within specified deadlines.`,
        `He promotes a collaborative environment by actively engaging with team members, mitigating anxiety around uncertainties.`,
        `Adapts to evolving strategic objectives through transparent and effective communication, ensuring alignment with company goals.`,
        `Proficient in promoting Custom Software Development, Digitalization in Industry, Game Development, and Roblox Art & Animation services.`,
        `Committed to creating interactive websites with user-friendly UI and visually appealing interfaces.`,
    ]
}

function RahulGoti() {

    const tabNameData = ['App Development', 'Game Development', 'Website Development', 'Art & Animation'];
    useEffect(() => {
        allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={rahulExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default RahulGoti